import request from '@/utils/request'

const headers = {
  'Content-Type': 'multipart/form-data'
}

// 上传图片请求
export function imageUploadRequest(data) {
  return request({
    url: '/admin/Upload/UploadFile',
    method: 'post',
    headers,
    data
  })
}
// base上傳
export function UploadFileBase64Request(data) {
  return request({
    url: '/admin/Upload/UploadFileBase64',
    method: 'post',
    data
  })
}

