<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>管理员角色</el-breadcrumb-item>
            <el-breadcrumb-item>设置功能</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">

        <el-form-item label="菜单模块">
          <el-tree :data="data" show-checkbox default-expand-all node-key="value" ref="tree" highlight-current :props="defaultProps">
          </el-tree>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="checkAll">全选</el-button>
          <el-button type="warning" @click="inverse">取消全选</el-button>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getdatilRequest, getmenutreeRequest } from '@/api/sys_role'
import ImageUpload from '@/components/imageUpload'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  typeName: 'Articletypecreate',
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        name: '',

        addtime: '',
      },
      data: [],
      channels: [], // 栏目初始数据
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  components: {
    'image-upload': ImageUpload,
  },

  created() {
    this.form.id = this.$route.query['id']

    this.getdatil()
  },
  methods: {
    back() {
      this.$router.back()
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([22, 42, 38])
    },
    getxz() {
      console.log(this.$refs.tree.getCheckedKeys())
    },
    checkAll() {
      this.$refs.tree.setCheckedNodes(this.data)
    }, // 反选
    inverse() {
      let res = this.$refs.tree
      let nodes = res.getCheckedNodes(true, true)
      this.batchSelect(this.channels, res, true, nodes)
    },
    // 全选处理方法
    batchSelect(nodes, refs, flag, seleteds) {
      if (typeof nodes != 'undefined') {
        nodes.forEach((element) => {
          refs.setChecked(element, flag, true)
        })
      }

      if (typeof seleteds != 'undefined') {
        seleteds.forEach((node) => {
          refs.setChecked(node, !flag, true)
        })
      }
    },

    getmenutree() {
      getmenutreeRequest().then((res) => {
        this.data = res.data
        if (this.form.powers) {
          this.$refs.tree.setCheckedKeys(this.form.powers.split(','))
        }
      })
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        this.form = res.data
        this.getmenutree()
      })
    },

    createAd() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        params.powers = this.$refs.tree.getCheckedKeys().join(',')
        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$message(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {},
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
