import request from '@/utils/request'
// 分页列表
export function getselectlistroleRequest(params) {
  return request({
    url: '/admin/sys_role/getselectlistrole',
    method: 'get',
    params
  })
}
// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/sys_role/getList',
    method: 'get',
    params
  })
}
// 分页列表
export function getmenutreeRequest(params) {
  return request({
    url: '/admin/sys_role/getmenutree',
    method: 'get',
    params
  })
}


// 添加 
export function addtypeRequest(data) {
  return request({
    url: '/admin/sys_role/add',
    method: 'post',
    data
  })
}


// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/sys_role/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/sys_role/del',
    method: 'get',
    params
  })
}

// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/sys_role/edit',
    method: 'post',
    data
  })
}



// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/sys_role/setStates',
    method: 'get',
    params
  })
}
// 修改状态
export function updatesortmenu(params) {
  return request({
    url: '/admin/sys_role/updatesortmenu',
    method: 'get',
    params
  })
}

// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/sys_role/setSort',
    method: 'get',
    params
  })
}